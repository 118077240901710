import React from 'react';
import { FaCheck, FaBox, FaTruck, FaCheckCircle } from 'react-icons/fa'; // Import necessary icons
import './VerticalStepper.css';

const COLORS = {
    primary: '#f44c00', // Filled color as requested
    gray4: '#bdc3c7',
    black: '#2c3e50'
};

export default function VerticalStepper({ status }) {
    // Define color for each step based on status
    const packingColor = (status === "PACKING" || status === "TRANSIT" || status === "DELIVERED") ? COLORS.primary : COLORS.gray4;
    const transitColor = (status === "TRANSIT" || status === "DELIVERED") ? COLORS.primary : COLORS.gray4;
    const deliveredColor = (status === "DELIVERED") ? COLORS.primary : COLORS.gray4;

    return (
        <div className="status-container">
            <div className="step-container">
                <div className="circle-icon no-animation-icon" style={{ backgroundColor: COLORS.primary }}>
                    <FaCheck color="white" /> {/* Icon for "Order Received" */}
                </div>
                <div className="step-line" style={{ backgroundColor: packingColor }} />
                <span className="step-text">Your order has been received</span>
            </div>

            <div className="step-container">
                <div className="circle-icon no-animation-icon" style={{ backgroundColor: packingColor }}>
                    <FaBox color="white" /> {/* Icon for "Packing" */}
                </div>
                <div className="step-line" style={{ backgroundColor: transitColor }} />
                <span className="step-text">The order is being packed</span>
            </div>

            <div className="step-container">
                <div className="circle-icon no-animation-icon" style={{ backgroundColor: transitColor }}>
                    <FaTruck color="white" /> {/* Icon for "Transit" */}
                </div>
                <div className="step-line" style={{ backgroundColor: deliveredColor }} />
                <span className="step-text">Your order has been picked up for delivery</span>
            </div>

            <div className="step-container">
                <div className="circle-icon no-animation-icon" style={{ backgroundColor: deliveredColor }}>
                    <FaCheckCircle color="white" /> {/* Icon for "Delivered" */}
                </div>
                <span className="step-text">Delivered!</span>
            </div>
        </div>
    );
}
