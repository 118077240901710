import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../Includes/Footer";
import secureLocalStorage from "react-secure-storage";
import GeneralService from "../../services/general.service";
import { useCart } from "../../context/CartContext";
import './ProductDetail.css'

export default function ProductDetail() {
  const { updateCartCounter, updateUserAddress, userInfo, decreaseQty, cartItems, addItemToCart } = useCart();
  console.log("location component starts");
  const loggedInId = secureLocalStorage.getItem("id");
  const navigate = useNavigate();

  // console.log(secureLocalStorage.getItem("id"));
  const location = useLocation();
  const { id: prodId, name, price, description, quantity_added, image, stock, stock_available, max_qty } = location.state || {};
  // console.log(location);
  // const [product, setProduct] = useState({ id: prodId, name: name, price: price, description: description, quantity_added: quantity_added, image: image });
  const product = { id: prodId, name: name, price: price, description: description, stock_available, quantity_added: quantity_added, image: image, max_qty: max_qty };
  const [quantity, setQuantity] = useState(0);
  const [cartCounter, setCartCounter] = useState(0);

  const getQuantityInCart = (productId) => {
    const item = cartItems.find(i => i.id === productId);
    return item ? item.quantity : 0;
  };


  const fetchData = async (id) => {
    try {
      let userId = userInfo.id;
      const response = await GeneralService.getProductCartById(id, userId);
      const { data } = response;
      const { response: res } = data;
      console.log(res);
      // console.log(`home-data=${cartData}`);
      // const { response: cartNo } = cartData;
      setQuantity(res);
    } catch (err) {
      console.log(err);
      setQuantity(0);
    }
  }

  const decreaseQuantity = (id, product) => {
    const decreaseQnty = async () => {
      try {
        // let userId = userInfo.id;
        // const response = await GeneralService.decreaseQty(userId, id);

        decreaseQty(product);
        // decreaseQty(product);
        // const timeout = 8000;
        // const response = await Promise.race([
        //   GeneralService.decreaseQty(userId, id),
        //   new Promise((_, reject) => setTimeout(() => reject(new Error('Request timeout')), timeout))
        // ]);
        // console.log(response.data.response);
        // if (response) {
        //   fetchData(prodId);
        //   getCartCounter();

        // } else {
        //   throw new Error('No response from the server');
        // }
      } catch (err) {
        console.log(err?.response?.data);
      }
    }
    decreaseQnty();

    // decreaseQty();
  };

  const getCartCounter = async () => {
    try {
      let userId = userInfo.id;
      const cartResponse = await GeneralService.cartCounterByUserId(userId);
      const { data: cartData } = cartResponse;
      // console.log(`home-data=${cartData}`);
      const { counter: cartNo, address } = cartData;
      setCartCounter(cartNo);
      updateCartCounter(cartNo);
      updateUserAddress(address);
    } catch (err) {
      console.log(err);
      setCartCounter(0);
    }
  }

  const cartAddition = (id, product) => {
    let allowdQty = product.max_qty;
    let allowedStock = product.stock_available;
    let addedQty = getQuantityInCart(id);

// console.log(`${allowdQty}, ${allowedStock}, ${addedQty}`);

    const addCart = async () => {

      try {
        addItemToCart(product);

      } catch (err) {
      }
    }
    // alert(product);
    // alert(`added=${addedQty}, allowed=${allowdQty}`);
    if (parseInt(addedQty) < parseInt(allowedStock)) {
      if (parseInt(addedQty) < parseInt(allowdQty)) {
        addCart();
      }
    }
  }

  return (
    <>
      <div className="bg-white shadow-sm px-4 pt-3">
        <div className="d-flex align-items-center">
          <div className="gap-3 d-flex align-items-center">
            <Link to="/">
              <i className="bi bi-arrow-left d-flex text-success h3 m-0 back-page"></i>
            </Link>
            <h3 className="fw-bold m-0">Product Details</h3>
            {
              /*
              <Link to="/logout" className="text-white">
                 <i className="bi bi-box-arrow-right d-flex m-0 h3 header-bell"></i>
               </Link>
              */
            }
          </div>
          {/* <div className="ms-auto gap-2 d-flex align-items-center">
            <a href="notifications.html" className="link-dark">
              <i className="bi bi-bell-fill d-flex m-0 h3 header-bell"></i>
            </a>
            <a className="toggle osahan-toggle text-dark m-0" href="#">
              <i className="bi bi-list d-flex m-0 h3 header-list"></i>
            </a>
          </div> */}
        </div>
      </div>

      <div className="p-4">
        <div className="row g-3">
          <div className="col-12 col-md-12">
            <div className="card rounded-4 shadow border-0 overflow-hidden search-list-item position-relative" style={{ minHeight: '350px' }}>
              <Link to="javascript:void(0)" className="text-decoration-none link-dark">
                <div className="position-relative">
                  <img src={`https://api.veggieking.pk/public/upload/${image}`} alt={name} className="img-fluid rounded-top" />
                </div>
                <div style={{ padding: '10px', paddingBottom: '10px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ flex: '1', fontSize: '16px', fontWeight: 'bold' }}>
                      {name}
                    </div>
                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                      Rs. {price}
                    </div>
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#333' }}>
                      Description
                    </div>
                    <div style={{ fontSize: '14px', color: '#555' }}>
                      {description}
                    </div>
                  </div>
                </div>
              </Link>
              <div
                className="d-flex justify-content-center"
                style={{ gap: "15px" }}
              >
                {stock_available > 0 ? (
                  <>
                    {getQuantityInCart(prodId) > 0 ? (
                      <>
                        <div className="minus-btn2" onClick={() => decreaseQuantity(prodId, product)}>
                          <i className="bi bi-dash-lg"></i>
                        </div>
                        <div className="quantity2">{getQuantityInCart(prodId)}</div>
                        <div className="add-btn2" onClick={() => cartAddition(prodId, product)}>
                          <i className="bi bi-plus-lg"></i>
                        </div>
                      </>
                    ) : (
                      <button
                        style={{
                          position: 'absolute',
                          bottom: '5px',
                          padding: '8px',
                          width: "90%",
                          backgroundColor: '#f44c00',
                          color: 'white',
                          border: 'none',
                          borderRadius: '4px',
                          fontWeight: 'bold',
                          cursor: 'pointer'
                        }}
                        onClick={() => cartAddition(prodId, product)}
                      >
                        Add to Cart
                      </button>
                    )}
                  </>
                ) : 
                <span className="out-of-stock-badge">Out of Stock</span>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="p-5"></div>
        <Footer />
      </div>
    </>
  );
}
