import React, { useEffect, useState } from "react"; // Import useEffect
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import { useCart } from "../../context/CartContext";
import "./Footer.css";
import secureLocalStorage from "react-secure-storage";

const Footer = () => {
    const { cartCounter } = useCart();
    const userId = secureLocalStorage.getItem("id");

    const location = useLocation(); // Get current location
    // State to track the active link
    const [activeLink, setActiveLink] = useState(location.pathname); // Initialize with current path

    // Update active link based on current location
    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location.pathname]); // Update whenever the path changes

    return (
        <div className="fixed-bottom footer-bg shadow-sm osahan-nav-bottom">
            <div className="row links">
                <div className="col osahan-nav-bottom-link text-center">
                    <Link 
                        to="/" 
                        className={`nav-link ${activeLink === '/' ? 'active' : ''}`} 
                    >
                        <span className={`icon-wrapper ${activeLink === '/' ? 'active' : ''}`}>
                            <i className="bi bi-house h1"></i>
                        </span>
                        <p className="m-0">Home</p>
                    </Link>
                </div>
                {userId && (
                    <div className="col osahan-nav-bottom-link text-center">
                        <Link 
                            to="/orders" 
                            className={`nav-link ${activeLink === '/orders' ? 'active' : ''}`} 
                        >
                            <span className={`icon-wrapper ${activeLink === '/orders' ? 'active' : ''}`}>
                                <i className="bi bi-gift h1"></i>
                            </span>
                            <p className="m-0">Orders</p>
                        </Link>
                    </div>
                )}
                <div className="col osahan-nav-bottom-link text-center">
                    <Link 
                        to="/cart" 
                        className={`nav-link ${activeLink === '/cart' ? 'active' : ''}`} 
                        style={{ position: "relative" }}
                    >
                        <span className={`icon-wrapper ${activeLink === '/cart' ? 'active' : ''}`}>
                            <i className="bi bi-basket h1"></i>
                        </span>
                        <p className="m-0">Cart</p>
                        {parseInt(cartCounter) > 0 && (
                            <span className="cart-counter">{cartCounter}</span>
                        )}
                    </Link>
                </div>
                <div className="col osahan-nav-bottom-link text-center">
                    <Link 
                        to={userId ? `/personal-info` : `/sign-in`} 
                        className={`nav-link ${activeLink === (userId ? '/personal-info' : '/sign-in') ? 'active' : ''}`} 
                    >
                        <span className={`icon-wrapper ${activeLink === (userId ? '/personal-info' : '/sign-in') ? 'active' : ''}`}>
                            <i className="bi bi-person h1"></i>
                        </span>
                        <p className="m-0">Profile</p>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Footer;
