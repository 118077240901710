import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../Includes/Footer";
import GeneralService from "../../services/general.service";
import secureLocalStorage from "react-secure-storage";
import { useCart } from "../../context/CartContext";

export default function CategoryProducts() {
   const location = useLocation();
   const navigate = useNavigate();
   const { addItemToCart, decreaseQty, removeItemFromCart, cartItems } = useCart();

   const loggedInId = secureLocalStorage.getItem("id");

   // const { id: catId, name: catName } = useParams();
   const { id: catId, name: catName } = location.state || {};
   console.log(`location=${JSON.stringify(location)}`);
   // console.log(catId);
   // const catId = 1;
   // const catName = "Vegetables";
   const [data, setData] = useState([]);
   const [cartCounter, setCartCounter] = useState(0);
   const [products, setProducts] = useState([]);

   const getQuantityInCart = (productId) => {
      // console.log(`id=${productId}`);
      const item = cartItems.find(i => i.id === productId);
      return item ? item.quantity : 0;
   };

   const getCartCounter = async () => {
      try {
         let userId = secureLocalStorage.getItem("id");
         const cartResponse = await GeneralService.cartCounterByUserId(userId);
         const { data: cartData } = cartResponse;
         // console.log(`home-data=${cartData}`);
         const { counter: cartNo, address } = cartData;
         setCartCounter(cartNo);

      } catch (err) {
         console.log(err);
         setCartCounter(0);
      }
   }

   const addCart = async (prodId, product) => {
      let allowedStock = product.stock_available;
      let allowdQty = product.max_qty;
      let addedQty = getQuantityInCart(prodId);

      // if (!loggedInId) {
      //    navigate("/sign-in");
      // }
      try {
         const updatedProducts = products.map(product => {
            if (product.id === prodId) {
               return {
                  ...product,
                  quantity_added: parseInt(product.quantity_added || 0) + 1
               };
            }
            return product;
         });

         if(parseInt(addedQty) < parseInt(allowedStock)) {
            if (parseInt(addedQty) < parseInt(allowdQty)) {
               setProducts(updatedProducts);
               addItemToCart(product);
            }
         }
      } catch (err) {
      }
   }

   const decreaseQuantity = (prodId, product) => {
      const decreaseQnty = async () => {
         try {
            const updatedProducts = products.map(product => {
               if (product.id === prodId) {
                  return {
                     ...product,
                     quantity_added: parseInt(product.quantity_added || 0) - 1
                  };
               }
               return product;
            });
            setProducts(updatedProducts);

            decreaseQty(product);

         } catch (err) {
            console.log(err?.response?.data);
         }
      }

      decreaseQnty();
   };

   const fetchProducts = async () => {
      try {
         // setProductsLoading(true);
         let userId = secureLocalStorage.getItem("id");
         const timeout = 8000;
         const response = await Promise.race([
            GeneralService.listProductByCatCart(catId, userId),
            new Promise((_, reject) => setTimeout(() => reject(new Error('Request timeout')), timeout))
         ]);

         if (response) {
            setProducts(response.data.response);
         } else {
            throw new Error('No response from the server');
         }
         // setProductsLoading(false);
         console.error('Featured products fetched');
      } catch (error) {
         // setProductsLoading(false);
         setProducts([]);
         console.error('Error fetching featured:', error);
      }
   };

   useEffect(() => {
      getCartCounter();
      fetchProducts();
   }, []);

   return (
      <>
         {/* <!-- Navbar --> */}
         <div className="bg-light shadow-sm px-4 pt-3">
            <div className="d-flex align-items-center">
               <div className="gap-3 d-flex align-items-center">
                  <Link to="/"><i className="bi bi-arrow-left d-flex text-success h3 m-0 back-page"></i></Link>
                  <button type="button" className="btn bg-none border-0 p-0" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                     <span className="text-success h4 m-0"><span>{catName}</span>&nbsp;</span>
                  </button>
               </div>
            </div>
         </div>
         <div className="p-4">
            <div className="row g-3">
               {products.length == 0 && (
                  <div style={{ textAlign: "center" }}>No items found</div>
               )}
               {products.map((product) => (
                  <div className="col-6 col-md-4">
                     <div className="card rounded-4 shadow border-0 overflow-hidden search-list-item">
                        <Link to="/product-detail" state={product} className="text-decoration-none link-dark">
                           <div className="position-relative">
                              <div className="product-back">
                                 <img
                                    src={`https://api.veggieking.pk/public/upload/${product.image}`}
                                    alt=""
                                    className="img-fluid rounded-top" />
                              </div>
                           </div>
                        </Link>
                        <div className="card-body">
                           <div className="mb-1">
                              <div className="h4 fw-bold m-0" style={{ fontWeight: "normal" }}>{product.name}</div>
                           </div>
                           <div className={`d-flex gap-2 text-muted m-0 fw-normal add-footer`}>
                              <div className={product.stock_available > 0 ? "" : "out-of-stock-badge"}>{product.stock_available > 0 ? `Rs. ${product.price}` : "Out of Stock"}</div>
                              {
                                 product.stock_available > 0 && (
                                    <>
                                       {getQuantityInCart(product.id) > 0 && (
                                          <>
                                             <div className="minus-btn" onClick={() => { decreaseQuantity(product.id, product) }}>
                                                <i className="bi bi-dash-lg"></i>
                                             </div>
                                             <div className="quantity">{getQuantityInCart(product.id)}</div>
                                          </>
                                       )}
                                       <div className="add-btn" onClick={() => { addCart(product.id, product) }}>
                                          <i className="bi bi-plus-lg"></i>
                                       </div>
                                    </>
                                 )
                              }
                           </div>
                        </div>
                     </div>
                  </div>
               ))}
            </div>
         </div>
         <div className="pt-4 pb-5"></div>
         {/* <!-- Fixed Bottom --> */}
         {/* <div className="fixed-bottom bg-white shadow px-3 py-2 osahan-nav-bottom">
            <div className="row links">
               <div className="col osahan-nav-bottom-link text-center">
                  <a href="your-order">
                     <span><i className="bi bi-truck h1"></i></span>
                     <p className="m-0">Delivery</p>
                  </a>
               </div>
               <div className="col osahan-nav-bottom-link p-0 text-center active">
                  <a href="/offers">
                     <span><i className="bi bi-percent h1"></i></span>
                     <p className="m-0">Offers</p>
                  </a>
               </div>
               <div className="col osahan-nav-bottom-link osahan-nav-bottom-link-center">
                  <a className="osahan-nav-bottom-home" href="/">
                     <span><i className="bi bi-house-fill h1"></i></span>
                  </a>
               </div>
               <div className="col osahan-nav-bottom-link p-0 text-center">
                  <a href="/cart">
                     <span><i className="bi bi-basket h1"></i></span>
                     <p className="m-0">Cart</p>
                  </a>
               </div>
               <div className="col osahan-nav-bottom-link p-0 text-center">
                  <a href="your-order">
                     <span><i className="bi bi-receipt h1"></i></span>
                     <p className="m-0">History</p>
                  </a>
               </div>
            </div>
         </div> */}
         <Footer counter={cartCounter} />
      </>
   );
}
