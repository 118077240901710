import React, { useState } from 'react'
import { forgotSchema, signUpSchema } from '../../schema';
import { useFormik } from 'formik';
import './SignUp.css'
import veggieLogo from '../Home/home-logo.png'
import { Link, useNavigate } from 'react-router-dom';
import GeneralService from '../../services/general.service';
import { toast } from 'react-toastify';
export default function Forgot() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  const [isEnable, setIsEnable] = useState(true)

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: forgotSchema,
      onSubmit: (values, action) => {
        handleForgot(values);
      },
    });

  const handleForgot = async (values) => {
    try {
      setIsLoading(true);
      setIsEnable(false);
      const response = await GeneralService.forgot(values); // Call the forgot method and capture the response
      console.log('Forgot response:', response); // Log the response to see the structure and extract the message if needed
      setIsLoading(false);
      setIsEnable(true);
      navigate("/otp-verify", { state: { email: values.email } });
    } catch (err) {
      if (err?.response?.status == 404) {
        toast.error("No user found");
      } else {
        toast.error("Server error. Please try again later.");
      }
      setIsLoading(false);
      setIsEnable(true);
    }
  };

  return (
    <div class="wrapper-new">
    <div class="signup-container">
        <div class="logo-container">
            <img src={veggieLogo} alt="Logo" class="logo" />
        </div>
        <div class="header-signup">
            <h1>Forgot Password</h1>
            <p>Please sign in to your existing account</p>
        </div>
        <form noValidate onSubmit={handleSubmit} className="signup-form">
            <label htmlFor="phone">Email</label>
            <input type="email" id="email" name="email" placeholder="Enter Email Address" value={values.email} onChange={handleChange} />
            {touched.email && errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
            <br/>
            <button type="submit" className="signup-button">Forgot Password</button>
          </form>
    </div>
</div>

  )
}