import React, { useState } from 'react';
import { signInSchema } from '../../schema';
import { useFormik } from 'formik';
import './SignUp.css'; // Ensure this file contains the updated styles

import veggieLogo from '../Home/home-logo.png'; // Ensure the path is correct
import { Link, useLocation, useNavigate } from 'react-router-dom';
import GeneralService from '../../services/general.service';
import { useCart } from '../../context/CartContext';
import secureLocalStorage from 'react-secure-storage';
import Swal from 'sweetalert2';

export default function SignIn() {
  const location = useLocation();
  const { page } = location.state || "";
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const { updateCartCounter } = useCart();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleLogin = async (values) => {
    try {
      setIsLoading(true);
      const response = await GeneralService.login(values);
      const { data } = response;
      const { user } = data;

      secureLocalStorage.setItem("id", user.id);
      secureLocalStorage.setItem("user_type", user.user_type);

      const cartResponse = await GeneralService.cartCounterByUserId(user.id);
      const { response: cartNo } = cartResponse.data;
      updateCartCounter(cartNo);

      if (user.user_type === 'user') {
        if (page) {
          window.location.href = page;
        } else {
          navigate("/");
        }
      } else {
        navigate("/rider-orders");
      }
    } catch (err) {
      Swal.fire({
        title: err?.response?.status === 401 ? 'Invalid Credentials' : 'Server Error',
        text: err?.response?.status === 401 ? 'You entered wrong phone / password' : 'Please try again later',
        icon: 'warning',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const { values, errors, touched, handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      phone: "",
      password: ""
    },
    validationSchema: signInSchema,
    onSubmit: handleLogin,
  });

  return (
<div class="wrapper-new">
    <div class="signup-container">
        <div class="logo-container">
            <img src={veggieLogo} alt="Logo" class="logo" />
        </div>
        <div class="header-signup">
            <h1>Sign In</h1>
            <p>Please sign in to your existing account</p>
        </div>
        <form className="signup-form" noValidate onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="phone">PHONE NO</label>
              <input
                type="text"
                id="phone"
                name="phone"
                placeholder="Enter Phone No"
                onChange={handleChange}
                value={values.phone}
                onBlur={handleBlur}
                className={touched.phone && errors.phone ? 'input-error' : ''}
              />
              {touched.phone && errors.phone && (
                <span className="error">{errors.phone}</span>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="password">PASSWORD</label>
              <div className="password-container">
                <input
                  type={passwordVisible ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Enter Password"
                  onChange={handleChange}
                  value={values.password}
                  onBlur={handleBlur}
                  className={touched.password && errors.password ? 'input-error' : ''}
                />
                <button
                  type="button"
                  className="toggle-password"
                  onClick={togglePasswordVisibility}
                  aria-label={passwordVisible ? "Hide password" : "Show password"}
                >
                  {passwordVisible ? '🙈' : '👁️'}
                </button>
              </div>
              {touched.password && errors.password && (
                <span className="error">{errors.password}</span>
              )}
            </div>
            <div className='forgot-side'>
              <Link to="/forgot-password" className='forgot'>Forgot Password?</Link>
            </div>
            <button type="submit" className="signup-button" disabled={isLoading}>
              {isLoading ? "Loading..." : "Log In"}
            </button>
            <div className='signup'>
              <span>Don't have an account? <Link to="/sign-up">Sign Up</Link></span>
            </div>
          </form>
    </div>
</div>

  );
}
