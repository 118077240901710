import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Includes/Footer";
import GeneralService from "../../services/general.service";
import VerticalStepper from "../VerticalStepper/VerticalStepper";
import Image from "../Image/Image";
import './YourOrder.css';

export default function YourOrders({ route }) {
  const location = useLocation();
  const [loading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const { orderId, orderStatus, orderNo, orderDate } = location.state || {};

  useEffect(() => {
    getOrderDetailById(orderId);
  }, []);

  const getOrderDetailById = async (id) => {
    try {
      setIsLoading(true);
      const ordersData = await GeneralService.listOrdersDetailByOrderId(id);
      const { data } = ordersData;
      const { response } = data;
      setIsLoading(false);
      setData(response);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setData([]);
    }
  }

  return (
    <>
      <div className="osahan-header-main py-3 px-4">
        <div className="d-flex align-items-center">
          <div className="gap-3 d-flex align-items-center">
            <Link to="/">
              <i className="bi bi-arrow-left d-flex text-success h3 m-0 back-page"></i>
            </Link>
            <h3 className="fw-bold m-0">Track Order</h3>
          </div>
        </div>
      </div>
      <div className="px-4">
        <div className="bg-white my-3">
          <div className="card rounded-4 border overflow-hidden">
            <div className="card-header p-3 border-bottom">
              <div className="d-flex justify-content-between">
                <div className="d-flex gap-2">
                  <div>
                    <h5 className="mb-1">{orderNo}</h5>
                    <p className="text-muted my-0">Ordered at {orderDate}</p>
                  </div>
                </div>
              </div>
            </div>
               <div className="items-list">
                  {data.map((res) => (
                        <div className="items-container">
                  <div className="d-flex align-items-center gap-2 mb-3">
                      <div className="text-muted-quantity">{res.quantity}&nbsp;x</div>
                      <div>{res.prod_name}</div>
                    </div>
                    </div>
                  ))}
                </div>
            <div className="grid-container">
              <div className="stepper-section">
                <VerticalStepper status={orderStatus} />
              </div>
              <div className="image-section">
                <Image orderStatus={orderStatus} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
