import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GeneralService from "../../services/general.service";
import { useCart } from "../../context/CartContext";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import "./Payment.css";
import codImage from "../Images/cod.png";
import Loader from "../Includes/Loader";

export default function Payment() {
  const [orderLoading, setOrderLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [minCharges, setMinCharges] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const [instruction, setInstruction] = useState("");
  const { cart, address, items, delivery, total } = location.state || {};
  const { clearCart, updateCartCounter, appLoader, updateAppLoader } =
    useCart();

  const handleChangeText = (text) => {
    setInstruction(text);
  };

  const fetchMinCharges = async () => {
    try {
      const response = await GeneralService.getMinCharges();
      const { data } = response;
      const { response: res } = data;
      setMinCharges(res.price);
    } catch (err) {
      setMinCharges(0);
    }
  };

  useEffect(() => {
    fetchMinCharges();
  }, []);

  const placeOrder = () => {
    const orderPlacement = async () => {
      setLoading(true);
      if (orderLoading) return;

      setOrderLoading(true);

      try {
        updateCartCounter("0");
        // const response = listCart();
        // console.log(response);
        let userId = secureLocalStorage.getItem("id");
        const timeout = 8000;
        const response = await Promise.race([
          GeneralService.placeOrder(cart, userId, address, instruction),
          new Promise((_, reject) =>
            setTimeout(() => reject(new Error("Request timeout")), timeout)
          ),
        ]);
        if (response) {
          let orderNumber = response.data.response;
          // console.log(response.data.response);
          secureLocalStorage.setItem("cart_counter", String(0));
          clearCart();
          navigate("/order-confirm", { state: { orderNo: orderNumber } });
          setLoading(false);
        } else {
          toast.error("No response from the server");
          throw new Error("No response from the server");
        }
        console.log(response);
      } catch (err) {
        setLoading(false);
        if (err?.response?.status === 404) {
          toast.error("No items in cart");
        } else if (err?.response?.status === 403) {
          toast.error("You are disabled, contact Admin");
        } else {
          toast.error("Error in order");
        }
        console.log(err);
      } finally {
        setLoading(false);
        setOrderLoading(false);
      }
    };
    updateAppLoader(true);

    let totalPrice = parseInt(total) + parseInt(delivery);
    if (parseInt(totalPrice) >= parseInt(minCharges)) {
      orderPlacement();
    } else if (parseInt(totalPrice) < parseInt(minCharges)) {
      toast.error(`Minimum order is Rs ${minCharges}`);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <header className="header py-3 px-4">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-flex align-items-center">
                <Link to="/cart" className="text-decoration-none">
                  <i className="bi bi-arrow-left text-success h3 m-0 back-page"></i>
                </Link>
                <h3 className="fw-bold m-0 ms-3">Checkout</h3>
              </div>
            </div>
          </div>
        </div>
      </header>
          <main className="container checkout-container">
            <div className="row">
              <div className="col-lg-8 col-md-12 mb-2">
                <div class="delivery-address">
                  <h3 class="fw-bold mb-3 text-accent">Delivery Address</h3>
                  <div>
                    <h6 class="address">{address}</h6>
                  </div>
                  <hr />
                  <h3 class="fw-bold mb-3 text-accent">
                    Delivery Instructions
                  </h3>
                  <input
                    type="text"
                    class="form-control shadow-none"
                    placeholder="Add delivery instructions"
                    onChange={(e) => {
                      handleChangeText(e.currentTarget.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="order-summary">
                  <h3 className="fw-bold mb-3 text-accent">Order Summary</h3>
                  <div className="summary-item d-flex align-items-center justify-content-between mb-2">
                    <div className="label fs-5 text-muted">Subtotal:</div>
                    <div className="value fs-5 text-dark">Rs {total}</div>
                  </div>
                  <div className="summary-item d-flex align-items-center justify-content-between mb-2">
                    <div className="label fs-5 text-muted">No. of Items:</div>
                    <div className="value fs-5 text-dark">{items}</div>
                  </div>
                  <div className="summary-item d-flex align-items-center justify-content-between mb-2">
                    <div className="label fs-5 text-muted">
                      Delivery Charges:
                    </div>
                    <div className="value fs-5 text-dark">Rs {delivery}</div>
                  </div>
                  <hr />
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="fw-bold text-accent">Total Amount</h3>
                    <div className="amount fs-5 text-dark">
                      Rs. {parseInt(total) + parseInt(delivery)}
                    </div>
                  </div>
                  <hr />
                  <h3 className="fw-bold mb-3 text-accent">Payment Method</h3>
                  <div className="d-flex align-items-center gap-3 glass-card">
                    <img src={codImage} alt="COD" className="img-fluid" />
                    {/* <div>
                <h6 className="mb-0">Cash On Delivery (COD)</h6>
            </div> */}
                  </div>
                </div>
              </div>
            </div>
          </main>

          <div class="fixed-bottom">
            <div class="d-grid">
              <button
                className="btn btn-accent btn-lg rounded-pill"
                onClick={() => placeOrder()}
                disabled={orderLoading}
              >
                Confirm Checkout
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
